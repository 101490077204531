.field-recorder-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
}

.field-recorder {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    flex: auto;
    color: var(--primary-font-color);
    background-color: var(--secondary-bg-color);
}

/* Add conditional padding for usermanagement */
.field-recorder:has(.user-management) {
    padding: 30px;
}

.recording-icons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    top: 0px;
}

.recording-icons .primary {
    display: flex;
    flex-direction: row;
}

.recording-icons.actions .icon-button {
    display: flex;
    flex-direction: row;
    color: var(--primary-font-color);
    background-color: #fdfdfd;
    border: 1px solid #ddd;
    border-radius: 2px;
}

.recording-icons.actions.option-container > *:last-child {
    margin-bottom: 20px;
}

.actions .icon-button.active {
    background-color: var(--recorder-btn-option-bg-color-highlight);
}

.actions .icon-button:hover {
    background-color: var(--recorder-btn-action-color-hover);
}

.icon-label.bug {
    color: var(--recorder-icon-label-bug-color);
}

.icon-label.event {
    color: var(--recorder-icon-label-event-color);
}

.icon-label.update {
    color: var(--recorder-icon-label-update-color);
}

.record-button-group {
    display: flex;
    flex-direction: column;
    position: relative;
    /* width: 100%; */
    padding: 10px;
    overflow: hidden;
    align-items: center;
}

.record.icon-button {
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
}

.record.icon-button.configure {
    background-color: var(--recorder-btn-secondary-bg-color);
}

.record.icon-button.configure:hover {
    background-color: var(--recorder-btn-secondary-bg-color);
}

.record-icon.configure span {
    flex: 0 0 33%;
}

.icon-button {
    color: var(--recorder-btn-secondary-color);
    display: flex;
    padding: 13px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    min-width: 150px;
    background-color: var(--recorder-btn-bg-color);
    font-size: 24px;
    border: none;
    justify-content: center;
}

.icon-button:hover {
    background-color: var(--recorder-btn-bg-color-hover);
    cursor: pointer;
}

.icon-button.disabled {
    background-color: var(--primary-button-disabled);
}

.icon-button.disabled:hover {
    background-color: var(--recorder-btn-action-color-hover);
    cursor: default;
}

.icon-button .icon {
    font-size: 24px;
}

.start-session-controller {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 20px;
    min-width: 800px;
}

.recorder-toolbox .toggle-session-form button {
    padding: 10px;
}

.software-versions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
}

.software-versions .software-item {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: center;
    color: var(--primary-font-color);
    font-size: 24px;
}

.software-versions .software-item div {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    color: var(--primary-font-color);
    /* padding: 10px; */
    font-size: 24px;
}

.start-session-controller input {
    display: flex;
    color: var(--primary-font-color);
    font-size: 24px;
}

.start-session-controller .session-title {
    display: flex;
}

.start-session-controller .session-title input {
    display: flex;
    flex-grow: 1;
}

.software-versions .software-item .title {
    justify-content: flex-start;
}

.software-versions .software-item .input-wrapper {
    justify-content: flex-end;
}

.form-slide {
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
}

.job-selection-dropdown {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

#job-select {
    font-size: 24px;
}

.start-session-title {
    font-size: 45px;
}

.recorder-prev-session-wrapper {
    width: 100%;
}

.toolbar {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    align-items: center;
    gap: 15px;
}

.toolbar .tool {
    display: flex;
    align-items: center;
}

.toolbar .tool .label {
    font-size: 16px;
    margin-bottom: 4px;
    margin-right: 10px;
    color: var(--primary-font-color);
}

.recorder-toolbox {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    padding: 15px;
    background-color: #ebeeef;
    z-index: 13;
    gap: 5px;
    flex-direction: column;
    width: 100%;
    padding: 0;
}

.recorder-toolbox.attached {
    box-shadow: 1px 1px 6px 1px #cbcbcb;
}

.resize-handle {
    width: 100%;
    height: 6px;
    cursor: ns-resize;
    position: absolute;
    bottom: -3px;
    display: flex;
}

/* Media Query for small screens */
@media (max-width: 1600px) {
    .field-recorder {
        padding: 0px;
    }

    .start-session-controller {
        min-width: 0;
        width: 100%;
    }
}

@media (max-width: 800px) {
    .field-recorder {
        gap: 0px;
    }

    .icon-button {
        display: flex;
        flex-direction: row;
        font-size: 20px;
    }

    .software-versions .software-item {
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .recorder-toolbox {
        gap: 0px;
    }

    span {
        text-wrap: wrap;
        overflow-wrap: anywhere;
    }

    .recorder-prev-session-wrapper .toolbar {
        flex-wrap: wrap;
    }
}
