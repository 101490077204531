.popup-parent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    color: #fff;
}

.popup-parent .popup-wrapper {
    position: relative;
    padding: 25px;
    color: black;
    display: flex;
    gap: 5px;
    flex-direction: column;
    background-color: var(--secondary-bg-color);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 50%);
}

.popup-parent .popup-wrapper .button-group {
    display: flex;
    gap: 5px;
}

.popup-wrapper > .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.popup-wrapper button {
    font-size: 20px;
}

.loading-icon {
    color: #fff;
    font-size: 24px;
    margin-left: 10px;
}
