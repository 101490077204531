.user-management {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 50px;
}

.user-management .group {
    display: flex;
    flex-direction: column;
}

.user-management .grouped-groups {
    display: flex;
    flex-direction: row;
    gap: 50px;
    flex-wrap: wrap;
}

.user-entry > .user-item {
    margin-right: 20px;
}

.user-entry > .delete-entry {
    padding: 0 5px;
    border-radius: 5px;
}

.user-entry > .delete-entry:hover {
    background-color: var(--primary-bg-red-color);
    color: white;
    cursor: pointer;
}

.user {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.add-group-to-user {
    display: flex;
    padding-top: 10px;
}

.add-group-to-user select {
    padding: 5px;
    font-size: 16px;
    margin: 0;
}

.user-name {
    font-weight: 700;
}

.user-roles .title,
.user-groups .title {
    font-weight: 600;
}

.user-roles .roles,
.user-groups .groups {
    margin-left: 5px;
}

.user-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    font-size: 16px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.user-list .title {
    margin-right: 20px;
}

.group-entry {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.user-management button {
    display: flex;
    background-color: var(--recorder-btn-bg-color);
    color: var(--recorder-btn-secondary-color);
    align-items: center;
    border: none;
    border-radius: 0;
}

.user-management button:hover {
    background-color: var(--recorder-btn-secondary-bg-color);
    cursor: pointer;
}
.user-management .group-title {
    margin-bottom: 10px;
    font-size: 24px;
}

.user-management .modify-groups {
    display: flex;
    gap: 40px;
}
.user-management .modify-groups .new-group {
    display: flex;
    flex-direction: column;
}

.user-management .modify-groups .new-group .input {
    display: flex;
}

.user-management .modify-groups .existing-groups .title {
    font-size: 16px;
    font-weight: 600;
}

.user-management .modify-groups .existing-groups .groups {
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.user-management .user-parent {
    display: flex;
    flex-grow: 1;
}

.user-management .users {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
}

.user-management .user-entry {
    display: flex;
    justify-content: space-between;
}
