.session-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.session-bubble {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 10px;
}

.session-bubble.selected {
    background-color: #007bff;
    border-color: #007bff;
}

.session-info {
    flex-grow: 1;
}

.generate-button {
    margin-top: 20px;
    text-align: center;
}

.report-body {
    padding: 15px;
    color: var(--primary-font-color);
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-bg-color);
    font-size: 18px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 10px 0;
}

.snapshot-description {
    display: flex;
    gap: 20px;
}

.report-body .stats {
    display: flex;
    /* flex-direction: column; */
    gap: 40px;
    margin-bottom: 20px;
}

.report-body .stats .group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
}

.report-body .stats .group .title {
    font-weight: 600;
}

.report-body .stats .data {
    display: flex;
    gap: 15px;
    margin-bottom: 20px;
}

.report-body .stats .entry {
    display: flex;
}

.report-body .summary {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.report-body .tile-header {
    font-weight: bold;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.report-body .tile-header .generation-attribution {
    font-size: 15px;
    font-weight: 300;
}

.report-body {
    display: flex;
    flex-direction: column;
}

.chart-container {
    padding: 20px;
}

.custom-tooltip {
    background-color: white;
}

.custom-tooltip .timestamp {
    padding: 10px;
}

.custom-legend {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.custom-legend li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}
.chart-container {
    margin-bottom: 50px; /* Adjust this value as needed */
}

@media (max-width: 1600px) {
    .session-select {
        width: 100%;
        padding: 0px;
        padding-block: 10px;
    }
    .report-body {
        padding: 0;
    }

    .chart-container {
        padding: 5px;
    }
}

@media (max-width: 800px) {
    .chart-container {
        padding: 5px;
        overflow-x: scroll;
    }
    .chart-container > div {
        height: 400px;
        width: 300vw !important;
    }
}
