.session-tile-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 100vw;
}

.session-list {
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.session-list .toolbar {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0px;
    padding: 10px;
    background-color: var(--secondary-bg-color);
    box-shadow: 0 2px 5px -1px rgb(0 0 0 / 30%);
    gap: 15px;
    z-index: 1;
}

.session-tile {
    padding: 15px;
    color: var(--primary-font-color);
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-bg-color);
    font-size: 18px;
    overflow: hidden;
}

.session-tile > .title {
    font-size: 22px;
    padding-bottom: 20px;
}

.session-tile .metadata {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin: 24px 0;
}

.session-tile .metadata.override-margin {
    margin: 0;
}

.session-tile .metadata > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: 300;
}

.session-tile .metadata .label {
    display: flex;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
}

.session-tile .metadata .recorder-software-versions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 0 0 50%;
    justify-content: space-between;
    gap: 40px;
}

.session-tile .metadata .recorder-software-versions > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    row-gap: 10px;
    flex-grow: 1;
}

.session-tile .metadata .recorder-software-versions .group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 40px;
    row-gap: 10px;
    justify-content: space-between;
    background-color: #ebeeef;
    padding: 10px;
    flex-grow: 1;
    margin-bottom: 10px;
}

.session-tile .metadata .recorder-software-versions .title {
    display: flex;
    font-size: 22px;
    font-weight: 500;
}

.session-tile .metadata .recorder-software-versions .versions > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    row-gap: 10px;
    padding-bottom: 5px;
    flex-grow: 1;
}

.session-tile .metadata .primary > div {
    display: flex;
    gap: 10px;
}

.session-tile .body {
    display: flex;
    flex-direction: column;
}

.session-tile .log-entry {
    border-radius: 5px;
    border: 1px solid #dddddd;
}

.session-tile .title-edit {
    display: flex;
}

.session-tile .title-edit button {
    display: flex;
    flex-grow: 1;
    align-items: center;
}

.session-tile .body .description .software-version-editor {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.session-tile .body .description .software-version-editor .input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.session-tile .session-data-wrapper {
    position: relative;
}

.session-tile .bottom-controls {
    display: flex;
    justify-content: space-between;
}

.log-entry > div {
    padding: 10px;
    font-size: 15px;
}

.tile-header {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.session-tile .controls,
.snapshot-tile .controls,
.tile-header .controls {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.link-button > label {
    cursor: pointer;
}

.link-button,
.resume-button,
.delete-button {
    background: none;
    border: none;
    color: var(--low-vis-font-color);
    cursor: pointer;
    font-size: 20px;
    padding: 5px;
}

.link-button:hover {
    color: var(--side-panel-btn-bg-color-hover);
}

.resume-button:hover {
    color: var(--primary-bg-green-color);
}

.delete-button:hover {
    color: var(--primary-bg-red-color);
}

.edit-button {
    background: none;
    border: none;
    color: var(--low-vis-font-color);
    cursor: pointer;
    font-size: 20px;
    padding: 5px;
}

.edit-button:hover {
    color: var(--primary-font-color);
}

.snapshots {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
}

.snapshots .title {
    font-weight: bold;
    margin-bottom: 5px;
}

.snapshot-tile {
    background-color: var(--job-tile-bg-color);
    padding: 10px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    flex-wrap: wrap;
}

.snapshot-tile .wrapper {
    position: relative;
    display: flex;
    text-wrap: wrap;
    justify-content: space-between;
}

.snapshot-tile .wrapper .images {
    display: flex;
    flex-wrap: wrap;
}

.snapshot-tile.selected {
    border-left: 5px solid red;
}

.snapshot-tile .header {
    padding-bottom: 10px;
}

.snapshot-tile .icon {
    font-size: 20px;
}

.snapshot-tile .icon.bug {
    color: var(--recorder-btn-bug-bg-color);
}

.snapshot-tile .icon.event {
    color: var(--recorder-btn-event-bg-color);
}

.snapshot-tile .icon.update {
    color: var(--recorder-btn-update-bg-color);
}

.snapshot-tile .classification {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.snapshot-tile .content {
    display: flex;
    flex-direction: column;
    text-wrap: balance;
    overflow-wrap: anywhere;
}

.snapshot-tile .timestamp {
    display: flex;
    flex-direction: column;
    font-weight: 300;
}

.snapshot-delta {
    display: flex;
    justify-content: center;
    font-weight: 200;
    font-style: italic;
}

.toolbar-small-screen {
    display: none;
}

@media (max-width: 1600px) {
    .session-tile .metadata {
        gap: 10px;
    }

    .session-tile .metadata .recorder-software-versions {
        justify-content: flex-start;
    }

    .snapshot-tile .header {
        padding-bottom: 10px;
        width: 100%;
    }
}

@media (max-width: 1400px) {
    .session-tile .metadata {
        gap: 20px;
        flex-direction: column;
    }

    .session-tile .metadata .recorder-software-versions {
        flex-wrap: wrap;
    }
    .snapshot-tile .wrapper {
        flex-direction: column;
    }

    .toolbar-small-screen {
        display: block;
        position: absolute;
        right: 0px;
    }
    .toolbar-large-screen {
        display: none;
    }
}

/* Media Query for small screens */
@media (max-width: 800px) {
    .tile-header .controls {
        justify-content: flex-end;
        width: 100%;
    }

    .tile-header {
        align-items: flex-start;
        flex-direction: column-reverse;
    }

    .machines-container .session-tile,
    .jobs-container .session-tile {
        padding: 0px;
    }

    .session-tile {
        padding: 5px;
    }

    .session-tile .metadata > div {
        font-size: 16px;
    }

    .session-tile .metadata {
        display: flex;
        flex-direction: column;
        padding: 5px;
        gap: 15px;
        margin-bottom: 15px;
    }

    .session-tile .metadata .recorder-software-versions {
        padding: 0px;
        gap: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex: 0 0 30%;
    }

    .snapshot-tile {
        flex-direction: column;
    }

    .snapshot-tile .content {
        text-wrap: wrap;
    }
}
