.render-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-container {
    cursor: pointer;
    position: relative;
    display: flex;
}

.loading-icon {
    position: relative;
    display: flex;
    align-items: center;
    color: var(--primary-bg-color);
    width: 150px;
    justify-content: center;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: zoom-out;
}

.fullscreen-image {
    max-width: 90%;
    max-height: 90%;
}

.thumbnail-image {
    width: 200px;
    max-width: 100%;
    height: auto;
    cursor: default;
}

.image-container .image {
    position: relative;
    display: flex;
    height: fit-content;
}

.icon-controls {
    bottom: 0;
    left: 0;
    display: flex;
    gap: 10px;
    position: absolute;
    right: 0px;
    background-color: #eff2f3e0;
    padding: 5px;
    justify-content: space-between;
}

.icon-controls .delete-icon,
.icon-controls .expand-icon {
    color: var(--low-vis-font-color);
    cursor: pointer;
}

.delete-icon:hover {
    color: var(--primary-bg-red-color);
}

.expand-icon:hover {
    color: var(--primary-font-color);
}

.upload-container {
    position: relative;
    display: flex;
    align-items: center;
}

.upload-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--primary-bg-color);
}

.upload-label:hover {
    color: var(--primary-font-color);
}

.upload-input {
    display: none;
}

.image-placeholder {
    color: var(--low-vis-font-color);
    font-size: 150px;
}
