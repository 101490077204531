#sidepanel-wrapper {
    transition: 600ms;
    width: 230px;
    background-color: var(--side-panel-bg-color);
    z-index: 14;
}

#sidepanel {
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: column;
    background-color: var(--side-panel-bg-color);
    width: 230px;
    margin-right: 0px;
    z-index: 1;
}

#sidepanel button.link {
    text-align: left;
    font-size: 20px;
    color: var(--side-panel-btn-color);
    background-color: var(--side-panel-btn-bg-color);
    border: none;
    padding: 20px;
    text-wrap: nowrap;
    display: flex;
    justify-content: space-between;
}

#sidepanel button.collapse {
    text-align: left;
    font-size: 20px;
    color: var(--side-panel-btn-color);
    border: none;
    padding: 20px;
    text-wrap: nowrap;
    display: flex;
    justify-content: space-between;
}

#sidepanel button.link.active {
    background-color: var(--side-panel-btn-bg-color-active);
}

#sidepanel button.link.active:hover {
    background-color: var(--side-panel-btn-bg-color-active);
}

#sidepanel button.link:hover {
    background-color: var(--side-panel-btn-bg-color-hover);
    cursor: pointer;
}

@media (max-width: 1600px) {
}

@media (max-width: 800px) {
    #sidepanel-wrapper {
        width: 100%;
        margin-left: 0px !important;
    }

    #sidepanel {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }
    #sidepanel button.collapse {
        display: none;
    }
    #sidepanel button.collapse,
    #sidepanel button.link {
        flex-grow: 1;
        justify-content: center;
    }
}
