.bug-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 15px;
}

.bug-form > div {
    display: flex;
    flex-direction: column;
}

.bug-form > div > label {
    font-size: 20px;
}

.bug-form > div > input,
.bug-form > div > textarea {
    font-size: 18px;
    padding: 7px;
    size-adjust: vertical;
    resize: vertical;
}

select {
    margin: 10px 0;
    padding: 5px;
    font-size: 16px;
}

.bug-reporter-parent-wrapper.hidden {
    display: none;
}

.bug-reporter-parent-wrapper {
    position: sticky;
    z-index: 11;
    width: 100%;
    max-height: 100%;
    overscroll-behavior: contain;
    overflow: auto;
    pointer-events: auto;
    position: relative;
}

.bug-reporter-parent-wrapper.enable-transition {
    transition: height 0.5s ease-in-out;
}

.bug-reporter-parent-wrapper.disable-overflow {
    overflow: hidden; /* Disable scrolling during transition */
}

.bug-reporter-parent-wrapper.override-height {
    max-height: unset;
    height: fit-content;
}

.bug-reporter-parent {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
}

.submenu {
    display: flex;
}

.submenu-title {
    font-size: 20px;
    font-weight: 600;
    margin-right: 10px;
}

.submenu select {
    margin-top: 0px;
}

.bug-reporter {
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
    color: var(--primary-font-color);
    position: relative;
}

.bug-reporter .title {
    font-size: 34px;
    margin-bottom: 5px;
}

.bug-reporter .controls {
    display: flex;
    flex-grow: 1;
    gap: 5px;
}

.bug-reporter .controls button {
    display: flex;
    flex-grow: 1;
}

.session-toolbar .dropdown-menu {
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    display: flex;
    visibility: hidden;
    gap: 5px;
}

.session-toolbar .dropdown-menu.open {
    visibility: visible; /* Only visible when open */
}

.session-toolbar.command-button-wrapper {
    display: flex;
    gap: 0px;
    z-index: 15;
    right: 0px;
    align-items: center;
    padding-right: 10px;
    height: 100%;
}

.session-toolbar .edit-button,
.session-toolbar .dropdown-button {
    margin-left: 0px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 22px;
    padding: 10px;
}

.session-toolbar .controls .command-button-wrapper {
    display: flex;
    padding: 5px;
    gap: 10px;
    border-radius: 5px;
}

.session-toolbar .command-button {
    padding: 25px 15px;
    background-color: #fdfdfd;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 52px;
    white-space: nowrap;
    text-overflow: ellipsis; /* Adds ellipsis (...) if text is too long */
    width: fit-content;
}

.session-toolbar .command-button:hover {
    background-color: #e0e0e0;
}
.session-toolbar .command-button span {
    padding-left: 10px;
}

@media (max-width: 800px) {
    .session-toolbar.command-button-wrapper {
        display: none;
    }

    .bug-reporter {
        padding: 0px 2px;
    }
}
