.device-list .status {
    font-weight: 600;
    padding: 10px;
}

.dropdown-menu {
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    display: flex;
    visibility: hidden;
    gap: 5px;
}

.dropdown-menu.open {
    visibility: visible; /* Only visible when open */
}

.tile-header.file-header {
    min-height: 52px;
}

.device-tile .controls {
    gap: 0;
    flex-wrap: wrap;
}

.controls .command-button-wrapper {
    display: flex;
    padding: 5px;
    gap: 10px;
    border-radius: 5px;
}

.controls .command-button-wrapper > div {
    display: flex;
}

.controls .command-button-wrapper.active {
    background-color: #eff2f3;
}

.command-button {
    padding: 25px 15px;
    background-color: var(--recorder-action-btn-bg-color);
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    height: 52px;
    white-space: nowrap;
    text-overflow: ellipsis; /* Adds ellipsis (...) if text is too long */
    width: fit-content;
}

.command-button:hover {
    background-color: #e0e0e0;
}

.edit-button,
.dropdown-button {
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
}

/* Container for device files list */
.device-files {
    display: flex;
    padding: 10px;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

/* Individual file card styling */
.device-file-card {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: #f5f8fa;
    padding: 10px;
    gap: 5px;
    border-radius: 3px;
}

/* File card header with file name and action buttons */
.device-file-header {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

/* Action buttons container */
.file-actions {
    display: flex;
    gap: 10px;
}

/* Text styles */
.file-label {
    font-weight: 600;
}

.file-value {
    font-weight: 300;
}

.no-files-message {
    font-weight: 300;
}

.command-button span {
    padding-left: 10px;
}

@media (max-width: 800px) {
    .controls .command-button-wrapper > div {
        flex-direction: column;
        width: fit-content;
    }
}
