body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
    --header-primary-color: #005f9b;
    --header-secondary-color: #015286;

    --secondary-bg-color: #eff2f3;
    --secondary-bg-color-transparent: #eff2f3f0;

    --secondary-bg-light-highlight-color: #747e92;

    --primary-bg-color: #cfcfcf;
    --primary-bg-red-color: #ff0000;
    --primary-bg-green-color: #008000;

    --side-panel-bg-color: #212121;
    --side-panel-btn-bg-color: #bc514100;
    --side-panel-btn-bg-color-hover: #2b3139;
    --side-panel-btn-bg-color-active: #094ea7;
    --side-panel-btn-color: #ededed;
    --primary-font-color: #1c2529;
    --low-vis-font-color: #8d9091;

    --recorder-btn-bg-color: #528aed;
    --recorder-btn-bg-color-hover: #4c82dd;

    --recorder-btn-action-color: #dfdfdf;
    --recorder-btn-action-color-hover: #f1f1f1;

    --recorder-action-btn-bg-color: #fdfdfd;

    --recorder-btn-bug-bg-color: #dfdfdf;
    --recorder-btn-event-bg-color: #dfdfdf;
    --recorder-btn-update-bg-color: #dfdfdf;

    --recorder-btn-option-bg-color-highlight: #a0beff;
    --error-message-color: #ff0000;

    --recorder-icon-label-bug-color: #aa2626;
    --recorder-icon-label-event-color: #e7b529;
    --recorder-icon-label-update-color: #0e6d16;

    --recorder-btn-secondary-bg-color: #3c79cf;
    --recorder-btn-secondary-color: #fff;

    --primary-button-font-color: #fff;
    --primary-input-color: #fff;

    --primary-button-disabled: #dfdfdf;

    --job-tile-bg-color: #fafdff;
}

@keyframes pulse {
    0% {
        color: #175e27;
    }
    50% {
        color: #23943b;
    }
    100% {
        color: #175e27;
    }
}

@keyframes pulse-offline {
    0% {
        color: #75201a;
    }
    50% {
        color: #b31c11;
    }
    100% {
        color: #75201a;
    }
}

.status {
    display: flex;
    justify-content: space-between;
    color: var(--primary-font-color);
}

.status .indicator {
    animation: pulse 3s infinite;
    margin-left: 10px;
}

.status .indicator.offline {
    animation: pulse-offline 3s infinite;
    margin-left: 10px;
}
