.slider-parent {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-wrapper {
    position: relative;
    width: 50px;
    height: 24px;
    background-color: #ccc;
    border-radius: 12px;
    cursor: pointer;
    z-index: 0;
}

.slider-wrapper.enabled {
    background-color: #2eab5f;
}

.slider-circle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.slider-wrapper.enabled .slider-circle {
    transform: translateX(26px);
}

.slider-wrapper.disabled .slider-circle {
    transform: translateX(0px);
}
